<template>
<b-card title="Account Refund">
    <div>
        <b-form ref="form">
            <!-- Row Loop -->
            <b-row ref="row">

                <!-- Item Name -->
                <b-col md="4">
                    <b-form-group label="Start Date" label-for="item-name">
                        <b-form-datepicker id="startdatepicker-buttons" today-button reset-button close-button v-model="startDate" locale="en" />
                    </b-form-group>
                </b-col>

                <!-- Cost -->
                <b-col md="4">
                    <b-form-group label="End Date" label-for="item-name">
                        <b-form-datepicker id="enddatepicker-buttons" today-button reset-button close-button v-model="endDate" locale="en" />
                    </b-form-group>
                </b-col>

                <!-- Profession -->

                <!-- Remove Button -->
                <b-col lg="2" md="3" class="mb-50">
                    <b-button v-ripple.400="'rgba(234, 84, 85, 0.15)'" variant="outline-danger" class="mt-0 mt-md-2" @click="showTables()">
                        <feather-icon icon="SearchIcon" class="mr-25" />
                        <span>Search</span>
                    </b-button>
                </b-col>
                <b-col cols="12">
                    <hr>
                </b-col>
            </b-row>
            <b-row ref="inboxList" v-show="showTable">
                <b-col md="2" sm="4" class="my-1">
                    <b-form-group class="mb-0">
                        <label class="d-inline-block text-sm-left mr-50">Per page</label>
                        <b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions" class="w-50" />
                    </b-form-group>
                </b-col>
                <b-col md="4" sm="8" class="my-1">
                    <b-form-group label="Sort" label-cols-sm="3" label-align-sm="right" label-size="sm" label-for="sortBySelect" class="mb-0">
                        <b-input-group size="sm">
                            <b-form-select id="sortBySelect" v-model="sortBy" :options="sortOptions" class="w-75">
                                <template v-slot:first>
                                    <option value="">
                                        -- none --
                                    </option>
                                </template>
                            </b-form-select>
                            <b-form-select v-model="sortDesc" size="sm" :disabled="!sortBy" class="w-25">
                                <option :value="false">
                                    Asc
                                </option>
                                <option :value="true">
                                    Desc
                                </option>
                            </b-form-select>
                        </b-input-group>
                    </b-form-group>
                </b-col>
                <b-col md="6" class="my-1">
                    <b-form-group label="Filter" label-cols-sm="3" label-align-sm="right" label-size="sm" label-for="filterInput" class="mb-0">
                        <b-input-group size="sm">
                            <b-form-input id="filterInput" v-model="filter" type="search" placeholder="Type to Search" />
                            <b-input-group-append>
                                <b-button :disabled="!filter" @click="filter = ''">
                                    Clear
                                </b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>
                </b-col>
                <b-table :per-page="perPage" :items="items" :current-page="currentPage" :fields="fields" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered" striped responsive>
                    <template #cell(show_details)="row">
                        <b-form-checkbox v-model="row.detailsShowing" plain class="vs-checkbox-con" @change="row.toggleDetails">
                            <span class="vs-checkbox">
                                <span class="vs-checkbox--check">
                                    <i class="vs-icon feather icon-check" />
                                </span>
                            </span>
                        </b-form-checkbox>
                    </template>
                </b-table>
                <b-col cols="12">
                    <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="center" size="sm" class="my-0" />
                </b-col>
            </b-row>
        </b-form>
    </div>

</b-card>
</template>

<script>
import {
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BButton,
    BFormDatepicker,
    BCard,
    BTable,
    BFormCheckbox,
    BBadge,
    BInputGroupAppend,
    BFormSelect,
    BInputGroup,
    BPagination
} from 'bootstrap-vue'
import {
    heightTransition
} from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import $ from 'jquery'
import store from '@/store'
export default {
    components: {
        BCard,
        BForm,
        BRow,
        BCol,
        BButton,
        BFormGroup,
        BFormInput,
        BFormDatepicker,
        BTable,
        BFormCheckbox,
        BBadge,
        BInputGroupAppend,
        BFormSelect,
        BInputGroup,
        BPagination
    },
    directives: {
        Ripple,
    },
    mixins: [heightTransition],
    data() {
        return {
            startDate: "",
            endDate: "",
            perPage: 10,
            totalRows: 1,
            currentPage: 1,
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
            pageOptions: [3, 5, 10, 50],
            nextTodoId: 2,
            showTable: false,

            fields: [
            { key: 'transacion_id', label: 'Transaction ID', sortable: true },
            
            { key: 'reference_type', label: 'Type', sortable: true },
            { key: 'amount', label: 'Amount', sortable: true },
            { key: 'source', label: 'Source'},
            { key: 'description', label: 'Description'},
            { key: 'created_at', label: 'Created on', sortable: true }, 
            ],
            items: [

            ],
            
        }
    },
    computed: {
        sortOptions() {
            // Create an options list from our fields
            return this.fields
                .filter(f => f.sortable)
                .map(f => ({
                    text: f.label,
                    value: f.key
                }))
        },
    },
    mounted() {
        this.initTrHeight()
        this.totalRows = this.items.length
    },
    created() {
        window.addEventListener('resize', this.initTrHeight)
    },
    destroyed() {
        window.removeEventListener('resize', this.initTrHeight)
    },
    methods: {
        showTables() {

            if (this.endDate == "" || this.startDate == "") {
                this.$swal({
                    title: 'Error!',
                    text: ' You need to select the date',
                    icon: 'error',
                    customClass: {
                        confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                })
            } else {
                let vm  = this
                vm.items = []
                $.get({
                    url: store.state.rootUrl + 'account/v1/view/transactions',
                    type: "get",
                    async: true,
                    data:{
                        start: vm.startDate,
                        end: vm.endDate,
                        referenceType: 3,
                        limit: 50
                    },
                    crossDomain: true,
                    headers: {
                        "Content-Type": "application/json",
                        "X-Requested-With": "XMLHttpRequest",
                        "X-Authorization-Key": vm.$cookies.get("token"),
                    },
                    success: function (response, status, jQxhr) {

                        if (response.data.code != 200) {
                            vm.$swal({
                                title: 'Error!',
                                text:  response.data.message,
                                icon: 'error',
                                customClass: {
                                    confirmButton: 'btn btn-primary',
                                },
                                buttonsStyling: false,
                            })
                        } else {
                            vm.showTable = true
                            let result = response.data.data;
                            for (var i = 0; i < result.length; i++) {
                                var contact = {
                                    created_at: result[i].created_at,
                                    reference_type: result[i].reference_type,
                                    amount: result[i].currency+''+result[i].amount,
                                    source: result[i].source,
                                    description: result[i].description,
                                    transacion_id: result[i].transacion_id,
                                }
                                vm.items.push(contact)
                            }
                            console.log("task filter " + JSON.stringify(result))
                        }
                    },
                    error: function (jQxhr, status, error) {
                        if (jQxhr.responseJSON.data.code == 401 || jQxhr.responseJSON.data.code == 500 || jQxhr.responseJSON.data.code == 403 || jQxhr.responseJSON.data.code == 404) {
                        vm.$cookies.remove("token");
                        vm.$cookies.remove("clientData");
                        // Redirect to login page
                        vm.$router.push({ name: 'login' })
                        vm.$toast({
                            component: ToastificationContent,
                            props: {
                                title: jQxhr.responseJSON.data.message,
                                icon: 'EditIcon',
                                variant: 'failed',
                            },
                        })
                    }
                    }
                });
                

            }
        },
        
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        initTrHeight() {
            this.trSetHeight(null)
            this.$nextTick(() => {
                this.trSetHeight(this.$refs.form.scrollHeight)
            })
        },
    },
}
</script>

<style lang="scss" scoped>
.repeater-form {
    overflow: hidden;
    transition: .35s height;
}
</style>
